//###########################################################################
// Contact form
//###########################################################################

import Vue from 'vue';
Vue.config.productionTip = false;
import { postToController } from '../Utils';
import { VueRecaptcha } from 'vue-recaptcha';

if (document.querySelector('#form')) {
    new Vue({
        el: '#form',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            fromName: null,
            fromEmail: null,
           // productRequirements: 'Choose product requirement',
            message: null,
            recaptchaVerified: false,
        },
        components: {
            VueRecaptcha
        },
        methods: {
            send: function(e) {
                e.preventDefault();

                this.errors = []

                if (!this.fromName) {
                    this.errors.push("Name required.");
                }
                if (!this.fromEmail) {
                    this.errors.push('Email required.');
                }
                if (!this.message) {
                    this.errors.push('Please enter a message.');
                }
                if (!this.recaptchaVerified) {
                    this.errors.push('Please confirm recaptcha.');
                }

                if (!this.errors.length) {
                    // If no form validation errors, allow form to submit as usual
                    //
                    e.target.submit()
                }
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },
        }
    });
}
