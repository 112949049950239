// import Reveal from './functions/scrollReveal';
import Navigation from './components/navigation';
import CookieMessage from './components/cookieMessage'
import Sliders from './components/sliders'
import ImageHandling from './functions/imageHandling'
import Form from './components/contactForm'
import lightSwitch from './components/lightSwitch'
import { scrollTo } from './functions/scrollTo'

// Default set-up
// document.querySelector('[data-scroll-trigger]').addEventListener('click', (e) => {
//     e.preventDefault();
//     scrollTo(document.querySelector(e.target.getAttribute("data-target")), 300);
// });

if (document.querySelector('.uploadhide')) {
    document.querySelector('.uploadhide').addEventListener('change', function () {
        let filename = this.value.replace('C:\\fakepath\\', '');
        document.querySelector('.uploadfile-dummy').value = filename;
    });
}

if (document.querySelector('#jumper') !== null) {
    document.querySelector('#jumper').addEventListener('click', function (e) {
        e.preventDefault();

        scrollTo(document.querySelector('#start'), 0);
    });
}