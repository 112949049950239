import Flickity from 'flickity';
import FlickityFade from 'flickity-fade';
require('flickity-imagesloaded');
require('flickity-as-nav-for');

window.addEventListener('load', function() {


	let homeSliderEle = document.querySelector('.slider-img');
	let homeSliderTextEle = document.querySelector('.slider-name');
	if (homeSliderEle) {


		let homeSlider = new Flickity(homeSliderEle, {
			pageDots: false,
			prevNextButtons: false,
			wrapAround: true,
			imagesLoaded: true,
			cellSelector: '.item',
			autoPlay: 4000
		});

		let homeHeroPrev = document.querySelectorAll('.home-hero-prev');
		let homeHeroNext = document.querySelectorAll('.home-hero-next');

		let homeHeroCount = document.querySelector('#num');
		homeSlider.on('change', function(index) {
			homeHeroCount.innerHTML = (index + 1);
			document.querySelector('.slider-bar-active').classList.remove('slider-bar-active');
			document.querySelector('[data-slider-bar="' + (index + 1) + '"]').classList.add('slider-bar-active');
		});

		for (let i = 0; i < homeHeroPrev.length; i++) {
			homeHeroPrev[i].addEventListener("click", (e) => {
				homeSlider.previous();
			});
		}

		for (let i = 0; i < homeHeroNext.length; i++) {
			homeHeroNext[i].addEventListener("click", (e) => {
				homeSlider.next();
			});
		}

		let homeTextSlider = new Flickity(homeSliderTextEle, {
			prevNextButtons: false,
			imagesLoaded: true,
			cellSelector: '.item',
			contain: true,
			pageDots: false,
			wrapAround: true,
			asNavFor: homeSliderEle,
			draggable: false
		});
	}

	let quoteSliderEle = document.querySelector('.slider-quote');
	if (quoteSliderEle) {
		let quoteSlider = new Flickity(quoteSliderEle, {
			pageDots: false,
			prevNextButtons: false,
			wrapAround: true,
			imagesLoaded: true,
			cellSelector: '.item',
			fade: true
		});

		let quotePrev = document.querySelectorAll('.quote-prev');
		let quoteNext = document.querySelectorAll('.quote-next');
		for (let i = 0; i < quotePrev.length; i++) {
			quotePrev[i].addEventListener("click", (e) => {
				quoteSlider.previous();
			});
		}

		for (let i = 0; i < quoteNext.length; i++) {
			quoteNext[i].addEventListener("click", (e) => {
				quoteSlider.next();
			});
		}
	}
});