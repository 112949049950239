import Axios from 'axios';

const axiosConfig = {
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': window.csrfTokenValue,
        'ACCEPTS': 'application/json'
    }
};

export {postToController};

/**
 * Post to a controller action via Ajax
 *
 * @param {String} url
 * @param {Object} data 
 * @param {Function} success 
 * @param {Function} failure 
 */
function postToController(url, data = {}, success = function () {}, failure = function () {}) {
    Axios.post('/' + url, data, axiosConfig)
        .then(success, failure);
}