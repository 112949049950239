export const scrollTo = (element, offset = 0) => {
    let elementTop = element.getBoundingClientRect().top
    let scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
    let scrollDistance = elementTop + scrollTop + offset

    window.scroll({
        top: scrollDistance,
        left: 0,
        behavior: 'smooth'
    });
}