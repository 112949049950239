import Vue from 'vue';
Vue.config.productionTip = false;

new Vue({
	el: '#header',
	data: {
		isOpen: false,
		elmOverlay: null,
		overlay: null,
		inEle: null,
	},
	mounted() {
		this.inEle = document.querySelectorAll('.in-ele');
	},
	methods: {
		toggle: function() {
			this.isOpen = !this.isOpen;
			document.body.classList.toggle('fix');

			if (this.isOpen === true) {
				for (let i = 0; i < this.inEle.length; i++) {
					this.inEle[i].classList.add('is-opened');
				}
			} else {
				for (let i = 0; i < this.inEle.length; i++) {
					this.inEle[i].classList.remove('is-opened');
				}
			}
		},
	}
});

